<template>
	<div data-component="category-edit">
		<validation-observer
			data-element="form"
			tag="form"
			ref="form"
			@submit.prevent="submit"
		>
			<input-field
				name="Title"
				type="text"
				rules="required"
				v-model="category.title"
			/>
			<input-field
				name="Slug"
				type="text"
				rules="required"
				v-model="category.slug"
			/>
			<actions
				:actions="getActions"
				@actionClick="onActionClick"
			/>
		</validation-observer>
	</div>
</template>

<script>

	import InputField          from '@/components/forms/InputField';
	import Actions             from '@/components/ui/Actions';
	import actionClick         from '@/mixins/actionClick';
	import invalidFormMessage  from '@/mixins/invalidFormMessage';
	import routeParams         from '@/mixins/routeParams';
	import api                 from '@/services/api';
	import { paramCase }       from 'change-case';

	export default {
		components: {
			InputField,
			Actions
		},
		mixins: [actionClick, invalidFormMessage, routeParams],
		props: {
			isCreate: {
				type: Boolean,
				default: false
			},
			cancelRoute: {
				type: String,
				default: ''
			},
			categoryId: {
				type: String,
				default: ''
			}
		},
		data: () => ({
			category: {
				title: '',
				id: undefined
			}
		}),
		computed: {
			getActions () {
				return {
					primary: [
						{
							text: this.isCreate ? 'Create' : 'Save',
							type: 'submit'
						},
						{
							text: 'Cancel',
							route: this.cancelRoute
						}
					],
					secondary: [
						(!this.isCreate ?
							{
								text: 'Delete',
								actionId: 'deleteCategory'
							} : false
						)
					].filter(Boolean)
				};
			},
			getBreadcrumbs () {
				return [
					{
						path: '/admin',
						text: 'Admin'
					},
					{
						path: '/admin/knowledgebase',
						text: 'Knowledgebase'
					},
					{
						path: '/admin/knowledgebase/categories',
						text: 'Categories'
					},
					(this.isCreate ?
						{
							path: '/admin/knowledgebase/categories/create',
							text: 'New category'
						} : false
					),
					(!this.isCreate ?
						{
							path: `/admin/knowledgebase/categories/${this.getCategoryId}`,
							text: this.category?.title
						} : false
					)
				].filter(Boolean);
			}
		},
		watch: {
			'category.title' (newVal, oldVal) {
				this.category.slug = paramCase(this.category.title);
			}
		},
		mounted () {
			if (this.isCreate) {
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				return this.$store.commit('ui/setLoadingIsHidden');
			}
			this.setCategory();
		},
		methods: {
			async setCategory () {
				const category = await api.admin.getKnowledgebaseCategoryById({
					categoryId: this.categoryId
				});
				if (!category) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.category = {
					...this.category,
					...category
				};
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			},
			async deleteCategory () {
				const response = await api.admin.deleteKnowledgebaseCategoryById({
					categoryId: this.categoryId
				});
				if (!response) {
					return false;
				}
				await this.$router.push(this.cancelRoute);
				this.$store.commit('ui/showNotification', {
					notification: 'Category has been deleted'
				});
			},
			async submit () {
				const valid = await this.$refs.form.validate();
				if (!valid) {
					this.showInvalidFormMessage();
					return;
				}
				if (this.isCreate) {
					const category = await api.admin.createKnowledgebaseCategory({
						category: this.category
					});
					if (!category?.id) {
						return;
					}
				} else {
					const category = await api.admin.updateKnowledgebaseCategoryById({
						category:    this.category,
						categoryId:  this.category.id
					});
					if (!category?.id) {
						return;
					}
				}
				await this.$router.push(this.cancelRoute);
				this.$store.commit('ui/showNotification', {
					notification: 'The category has been ' + (this.isCreate ? 'created' : 'updated')
				});
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='category-edit'] {}

</style>
