<template>
	<div data-route>
		<page-header
			heading="Edit category"
		/>
		<div data-element="main">
			<category-edit
				:categoryId="getCategoryId"
				:cancelRoute="getCancelRoute"
				:isCreate="false"
			/>
		</div>
	</div>
</template>

<script>

	import PageHeader    from '@/components/ui/PageHeader';
	import CategoryEdit  from '@/components/knowledgebase/edit/CategoryEdit';

	export default {
		metaInfo: {
			title: 'Edit category'
		},
		components: {
			PageHeader,
			CategoryEdit
		},
		computed: {
			getCancelRoute () {
				return '/admin/knowledgebase/categories';
			},
			getCategoryId () {
				return this.$route.params.categoryId;
			}
		},
		created () {}
	};
</script>
